/*
* From http://mcg.mbitson.com/
*/
$auram-primary: (
  50 : #e0f3f0,
  100 : #b3e2da,
  200 : #80cfc2,
  300 : #4dbca9,
  400 : #26ad96,
  500 : #009f84,
  600 : #00977c,
  700 : #008d71,
  800 : #008367,
  900 : #007254,
  A100 : #a1ffe1,
  A200 : #6effd1,
  A400 : #3bffc2,
  A700 : #21ffb9,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$auram-warn: (
  50 : #f7e0e0,
  100 : #eab3b3,
  200 : #dd8080,
  300 : #cf4d4d,
  400 : #c42626,
  500 : #ba0000,
  600 : #b30000,
  700 : #ab0000,
  800 : #a30000,
  900 : #940000,
  A100 : #ffbfbf,
  A200 : #ff8c8c,
  A400 : #ff5959,
  A700 : #ff4040,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )
  );

  $auram-accent: (
    50 : #eef8f7,
    100 : #d5edec,
    200 : #b9e2df,
    300 : #9cd6d2,
    400 : #87cdc9,
    500 : #72c4bf,
    600 : #6abeb9,
    700 : #5fb6b1,
    800 : #55afa9,
    900 : #42a29b,
    A100 : #f6fffe,
    A200 : #c3fffb,
    A400 : #90fff7,
    A700 : #77fff5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
