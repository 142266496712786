//Use the same font-family name of entire project, to use this font when font-weight is "bold"
//see https://www.456bereastreet.com/archive/201012/font-face_tip_define_font-weight_and_font-style_to_keep_your_css_simple/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import '~@angular/material/theming';
$custom-typography: mat-typography-config($font-family: 'Open Sans, sans-serif', $headline: mat-typography-level(32px, 48px, 700), $body-1: mat-typography-level(16px, 24px, 500));
@include angular-material-typography($custom-typography);

* {
  font-family: 'Open Sans', sans-serif;
}
