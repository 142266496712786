.u-center-text {
  text-align: center;
}

.u-mb-small {
  margin-bottom: 1.5rem;
}

.u-mb-medium {
  margin-bottom: 4rem;
}

.u-mb-big {
  margin-bottom: 8rem;
}

.u-mt-small {
  margin-top: 1.5rem;
}

.u-mt-medium {
  margin-top: 4rem;
}

.u-mt-big {
  margin-top: 8rem;
}

.u-full-width {
  width: 100%;
}

.u-flex {
  display: flex !important;
  flex-wrap: wrap;
}

.u-flex-full {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.u-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.u-flex-column {
  display: flex !important;
  flex-direction: column;
}

.u-flex-align-center {
  display: flex !important;
  align-items: center;
}

.u-capitalize {
  text-transform: capitalize;
}

.mat-list-text {
  padding-left: 0 !important;
}

@media only screen and (max-width: 600px) {
  .u-flex-full {
    flex-wrap: wrap !important;
  }
  button {
    margin-left: 20px !important;
    margin-bottom: 20px !important;
  }


  .cdk-overlay-pane {
    margin-top: 24px !important;
  }

  #mat-dialog-0>app-appraisal-mobile-edit>mat-dialog-content>form>mat-form-field>div>div.mat-form-field-flex.ng-tns-c30-2 {
    margin-top: 16px;
    padding: 0 8px !important;
  }

  // .mat-form-field-appearance-legacy .mat-form-field-underline {
  //   height: 4px;
  //   width: 90%;
  //   margin-left: 8px;
  // }
  .mat-select-arrow {
    margin: 0 24px !important;
  }
}

.main-container {
  top: 55px !important;
}

.close,
.add {
  height: 24px !important;
  width: 24px !important;
  vertical-align: baseline !important;

  mat-icon {
    font-size: 16px !important;
  }
}

.close.mat-icon-button {
  line-height: 24px !important;
}

.add.mat-icon-button {
  line-height: 24px !important;
}

.mat-form-field {
  line-height: 1.5 !important;
}

.fill-remaining {
  flex: 1 1 auto;
}
